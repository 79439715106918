<template lang="pug">
a-form-model(ref="form" :model="detail" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 19, offset: 1 }")

  a-form-model-item.m-b-10(label="时间" prop="title")
    a-input(v-model="detail.title" placeholder="请输入时间")

  a-form-model-item.m-b-10(label="描述" prop="excerpt")
    a-textarea(v-model="detail.excerpt" placeholder="请输入描述" :auto-size="{ minRows: 6, maxRows: 8 }")

  a-form-model-item.m-b-10(label="排序" prop="menu_order")
    a-input-number(v-model="detail.menu_order" :min="1")

  a-form-model-item.m-b-10(:wrapper-col="{ span: 14, offset: 4 }")
    a-button(type="primary" :loading="loading" @click="handleSubmit('form')") 提交
    a-button.m-l-10(type="default" @click="handleReset('form')") 重置

  //- .flex.fs-11
    pre(style="width: 33.33%; overflow: hidden;") {{ rules }}
    pre(style="width: 33.33%; overflow: hidden;") {{ data }}
    pre(style="width: 33.33%; overflow: hidden;") {{ detail }}
</template>

<script>
export default {
  name: 'HistoryForm',
  props: {
    data: {
      type: Object,
      default: null
    },
    //- rules: {
    //-   type: Object,
    //-   default: null
    //- }
  },
  data() {
    return {
      rules: {
        title: [
          { required: true, message: '时间必须', trigger: 'blur' }
        ],
        excerpt: [
          { required: true, message: '描述必须', trigger: 'blur' }
        ],
        menu_order: [
          { required: true, message: '排序必须', trigger: 'blur' }
        ]
      },
      loading: false,
      detail: {}
    }
  },
  methods: {
    /*********************************************************
     * @description 获取数据详情
     *********************************************************/
    async getDetail() {
      if (!this.data.id) return;
      try {
        this.detail = await this.$http.get(`history/${this.data.id}`);
      } catch(err) {
        this.$message.error(err.message);
      }
    },

    /*********************************************************
     * @description 表单提交
     *********************************************************/
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (!valid) return;
        try {
          this.loading = true;

          const method = this.detail.id ? 'put' : 'post';
          const url = this.detail.id ? `history/${this.detail.id}` : `history`;

          await this.$http[method](url, this.detail);
          
          this.loading = false;
          this.$message.success('提交成功!');
          this.$refs[name].resetFields();
          
          this.$parent.$parent.$emit('ok');
        } catch (err) {
          this.loading = false;
          this.$message.error(err.message);
        }
      });
    },
    
    /*********************************************************
     * @description 重置表单
     *********************************************************/
    handleReset(name) {
      //- this.$parent.$parent.$emit('ok');
      //- this.$parent.$parent.$emit('close');
      //- this.$parent.$parent.$emit('cancel');
      //- this.$parent.$parent.close();

      this.$refs[name].resetFields();
    }
  },
  created() {
  },
  mounted() {
    this.getDetail();
  }
}
</script>

<style lang="less" scoped>
.secondary-sidebar-right {
  float: left;
  width: 310px;
  margin-right: -310px;
}
.inner-content-right {
  float: left;
  width: 100%;
  margin-right: 15px;
}

.history-form {
  .ant-divider { margin: 16px 0;}
  .ant-form-item {
    margin: 12px 0;
  }
  .ant-card {
    .ant-form-item {
      margin: 6px 0;
      label { font-size: 12px;}
    }
    .ant-form-item-label { line-height: 35px;}
    .ant-form-item-control { line-height: 36px;}
    .ant-input,
    .ant-input-number {
      height: 30px;
    }
  }
}
</style>